<template>
  <cta-banner :banner="banner" />
</template>
<script>
import CtaBanner from "./CtaBanner";
import {banners, NAMES} from "./banners";

export default {
  name: "Banner003Arrullando",
  components: {
    CtaBanner,
  },
  created () {
    this.banner = banners[NAMES.ARRULLANDO]
  }
};
</script>
